<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">二阶段最小二乘法分析结果</h1>
            

            <div class="result-area" id="varsProduce">
                <divider-title name="变量解释" />
                <div v-if="varsProduce">
                    <div v-for="(item,key) in varsProduce" :key="key">
                        【{{item.typeName}}】{{item.key}}:{{item.value}}
                    </div>
                </div>
                
            </div>

            <div class="result-area" v-if="modelSummary" id="modelSummary">
                <divider-title name="模型汇总" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px">R</th>
                                                    <th style="width:100px">R<sup>2</sup></th>
                                                    <th style="width:100px">调整R<sup>2</sup></th>
                                                    <th style="width:100px">估计标准误差</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0">
                                                    <td>{{toFixedNum(modelSummary.r)}}</td>
                                                    <td>{{toFixedNum(modelSummary.r2)}}</td>
                                                    <td>{{toFixedNum(modelSummary.adjR2)}}</td>
                                                    <td>{{toFixedNum(modelSummary.stdErrorOfEst)}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="varianceAnalysis" id="varianceAnalysis">
                <divider-title name="方差分析表" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:150px"></th>
                                                    <th style="width:150px">平方和</th>
                                                    <th style="width:150px">df</th>
                                                    <th style="width:150px">均方</th>
                                                    <th style="width:150px">F</th>
                                                    <th style="width:150px">Sig.</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>回归</td>
                                                    <td>{{toFixedNum(varianceAnalysis.ess)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.essDF)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.essMeanSq)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.fstats)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.sigFStats)}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>残差</td>
                                                    <td>{{toFixedNum(varianceAnalysis.rss)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.rssDF)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.rssMeanSq)}}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>总计</td>
                                                    <td>{{toFixedNum(varianceAnalysis.tss)}}</td>
                                                    <td>{{toFixedNum(varianceAnalysis.tssDF)}}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="coefficients" id="coefficients">
                <divider-title name="系数" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="" v-if="coefficients.coefficentName">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th rowspan="2" style="width:150px"></th>
                                                    <th colspan="2">未标准化系数</th>
                                                    <th rowspan="2" style="width:150px">标准化系数</th>
                                                    <th rowspan="2" style="width:150px">t</th>
                                                    <th rowspan="2" style="width:150px">Sig.</th>
                                                    <th rowspan="2"></th>
                                                </tr>
                                                <tr>
                                                    <th style="width:150px">系数</th>
                                                    <th style="width:150px">标准误差</th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{coefficients.coefficentName[0]}}</td>
                                                    <td>{{toFixedNum(coefficients.coefficients[0])}}</td>
                                                    <td>{{toFixedNum(coefficients.stdDeviaModelCoe[0])}}</td>
                                                    <td>{{toFixedNum(coefficients.standCoeff[0])}}</td>
                                                    <td>{{toFixedNum(coefficients.tstats[0])}}</td>
                                                    <td>{{toFixedNum(coefficients.sigt[0])}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{coefficients.coefficentName[1]}}</td>
                                                    <td>{{toFixedNum(coefficients.coefficients[1])}}</td>
                                                    <td>{{toFixedNum(coefficients.stdDeviaModelCoe[1])}}</td>
                                                    <td>{{toFixedNum(coefficients.standCoeff[1])}}</td>
                                                    <td>{{toFixedNum(coefficients.tstats[1])}}</td>
                                                    <td>{{toFixedNum(coefficients.sigt[1])}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    computed:{
        result() {
            let data = getSessionItem('analysis_leastSquares');
            return data.analysis_leastSquares;
        },
        parts() {
            let arr = [
                {name: '变量解释', keys: ['varsProduce']},
                {name: '模型汇总', keys: ['modelSummary']},
                {name: '方差分析表', keys: ['varianceAnalysis']},
                {name: '系数', keys: ['coefficients']}
            ]
            return arr;
        },
        varsProduce() {
            if(this.result) {
                return this.result.tables.varsProduce;
            } else {
                return null;
            }
        },
        modelSummary() {
            if(this.result) {
                return this.result.tables.modelSummary;
            } else {
                return null;
            }
        },
        varianceAnalysis() {
            if(this.result) {
                return this.result.tables.varianceAnalysis;
            } else {
                return null;
            }
        },
        coefficients() {
            if(this.result) {
                return this.result.tables.coefficients;
            } else {
                return null;
            }
        }

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            }else {
                return '-';
            }
        }, 
    }
}
</script>